




















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import { getExportExcel } from '@/utils/export'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  components: {
    Table,
    Pagination,
    ImgView
  }
})
export default class extends Vue {
  $constants: any
  carInfo = {
    carNo: '',
    parkingAverageTime: '',
    parkingNumber: '',
    parkingTotalTime: '',
    recentlyInTime: '',
    recentlyParkingTotalTime: ''
  }
  listLoading = false
  tableHeader = [
    {
      key: 'inPhoto',
      name: '入场照片',
      width: '14%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '车牌号',
      width: '15%'
    },
    {
      key: 'inTime',
      name: '进入时间',
      width: '16%'
    },
    {
      key: 'inDoor',
      name: '位置',
      width: '15%'
    },
    {
      key: 'outTime',
      name: '离开时间',
      width: '16%'
    },
    {
      key: 'outDoor',
      name: '位置',
      width: '15%'
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  imgUrl = ''

  created() {
    if (this.$route.query.carNo) {
      this.getCarInfo()
      this.getCarRecordList()
    }
  }

  // 获取车辆信息（车辆进出历史详情）
  getCarInfo() {
    this.$api.car
      .getCarHistoryInfo(this.$route.query.carNo)
      .then((res: any) => {
        const data = res.data.data
        this.carInfo = {
          carNo: data.carNo || '',
          parkingAverageTime: this.transform(data.parkingAverageTime) || '',
          parkingNumber: data.parkingNumber || '',
          parkingTotalTime: this.transform(data.parkingTotalTime) || '',
          recentlyInTime: data.recentlyInTime || '',
          recentlyParkingTotalTime:
            this.transform(data.recentlyParkingTotalTime) || ''
        }
      })
  }

  // 获取进出记录列表
  getCarRecordList() {
    this.listLoading = true
    this.$api.car
      .getRecordList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        carNo: this.$route.query.carNo
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 把秒转换成时分秒
  transform(seconds: any) {
    const duration = moment.duration(seconds, 'second')
    if (seconds > 86400) {
      const d2 = moment.duration(seconds - 86400, 'second')
      const h1 = d2.hours()
      const h2 = parseInt((seconds / 86400).toString()) * 24 + h1
      const min = d2.minutes()
      const second = d2.seconds()
      const res =
        h2 +
        '小时' +
        (min < 10 ? `0${min}` : min) +
        '分钟' +
        (second < 10 ? `0${second}` : second) +
        '秒'
      return res
    } else {
      const hour = duration.hours()
      const min = duration.minutes()
      const second = duration.seconds()
      let res = ''
      if (hour == 0 && min > 0) {
        res =
          (min < 10 ? `0${min}` : min) +
          '分钟' +
          (second < 10 ? `0${second}` : second) +
          '秒'
      } else if (hour == 0 && min == 0) {
        res = (second < 10 ? `0${second}` : second) + '秒'
      } else {
        res =
          (hour < 10 ? `0${hour}` : hour) +
          '小时' +
          (min < 10 ? `0${min}` : min) +
          '分钟' +
          (second < 10 ? `0${second}` : second) +
          '秒'
      }
      return res
    }
  }

  // 批量导出
  doExport() {
    const p = {
      exportType: 'carTravelRecord',
      carNo: this.$route.query.carNo
    }
    getExportExcel(p, '车辆进出历史记录导出.xlsx')
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 返回
  goback() {
    this.$router.go(-1)
  }
}
